import styled, {css} from "styled-components";
import Image from "next/image";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const Title = styled.h3`
  ${({ theme: { breakpoints } }) => css`
    font-size: 24px;
    font-weight: 400;
    text-align: center;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      font-size: 18px;
    }
  `}
`

export const Description = styled.span`
  font-size: 14px;
  font-weight: 400;
`

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ModalOverlay = styled.div`
  ${({ theme: { colors } }) => css`
    position: fixed;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.deepBlackOpacity};
    display: flex;
    justify-content: center;
    z-index: 20;
    padding-top: 20px;
    align-items: center;
  `}
`;

export const ModalWrap = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    position: absolute;
    background: ${colors.white};
    border-radius: 10px;
    max-width: min(50%, 450px);
    width: 100%;
    max-height: min(calc(100% - 80px), 740px);
    padding: 48px 24px 48px;
    display: flex;
    justify-content: center;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      max-width: min(80%, 450px);
    }
  `}
`;

export const IconCloseWrap = styled.div`
  position: absolute;
  margin: 0;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    fill: red;
  }
`;

export const ButtonClose = styled.button`
  ${({ theme: { breakpoints } }) => css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    top: 23px;
    right: 24px;
    border: none;
    background-color: transparent;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      top: 23px;
      right: 23px;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      width: 18px;
      height: 18px;
      top: 15px;
      right: 15px;
    }
  `}
`;

export const DownloadLabel = styled.div`
  ${({ theme: { colors } }) => css`
    width: 100%;
    height: 86px;
    border: 1px dashed ${colors.black};
    border-radius: 10.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 16px;

    input {
      display: none;
    }
  `}
`;

export const DownloadText = styled.p<{ color?: string }>`
  ${({ theme: { colors }, color }) => css`
    font-size: 13px;
    line-height: 1.3;
    text-align: center;
    margin: 0;
    color: ${color ? color : colors.darkGray};

    span {
      font-size: 12px;
      line-height: 1.5;
    }
  `}
`;

export const ScanGuideImage = styled(Image)`
  ${({ theme: { breakpoints } }) => css`
    width: 70%;

    @media (max-width: ${breakpoints.mobileWidth}) {
      width: 100%;
    }
  `}
`;
