import React, {FC, useCallback, useEffect, useState} from "react";
import * as S from "../barcodeModal/styled";
import Quagga from 'quagga';
import {useAppDispatch} from "@app/store";
import {fetchSearchProducts} from "@app/store/myGoods/slice";
import IconClose from "../../../../images/icon-close.png";
import {useDropzone} from "react-dropzone";
import ScanGuide from "@app/images/scan-guide.png";
import {useTranslation} from "next-i18next";
import Image from "next/image";

interface IBarcodeModal {
    onClose: () => void;
    setCode(code: string): void;
}

export const IMAGE_MAX_SIZE = 10485760

const BarcodeModal: FC<IBarcodeModal> = ({onClose, setCode}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [image, setImage] = useState();
    const [barcodeNotFound, setBarcodeNotFound] = useState(false)

    const setBarcode = (value) => {
        setCode(value);
        onClose();
        dispatch(fetchSearchProducts({q: value}));
    }

    const onDrop = useCallback(acceptedFiles => {
        if (!!acceptedFiles.length) {
            setImage(acceptedFiles)
        }
    }, []);

    useEffect(() => {
        if (image) {
            Quagga.decodeSingle(
                {
                    src: URL.createObjectURL(image[0]),
                    numOfWorkers: 0,
                    inputStream: {
                        type: "ImageStream",
                        size: 800
                    },
                    locate: true,
                    decoder: {
                        readers:["code_128_reader",
                            "ean_reader",
                            "ean_8_reader",
                            "code_39_reader",
                            "code_39_vin_reader",
                            "codabar_reader",
                            "upc_reader",
                            "upc_e_reader",
                            "i2of5_reader",
                            "2of5_reader",
                            "code_93_reader"
                        ]
                    }
                },
                function (result) {
                    if (result && result.codeResult) {
                        setBarcode(result.codeResult.code);
                    } else {
                        setBarcodeNotFound(true)
                    }
                }
            );
        }
    }, [image])

    const { getRootProps, getInputProps, isDragActive, fileRejections, open } = useDropzone({
        onDrop,
        maxSize: IMAGE_MAX_SIZE,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/*': []
        },
        multiple: false
    });

    return (
        <S.ModalOverlay>
            <S.ModalWrap>
                <S.ButtonClose onClick={onClose}>
                    <S.IconCloseWrap>
                        <Image src={IconClose} alt={'close'} width={20} height={20}/>
                    </S.IconCloseWrap>
                </S.ButtonClose>
                <S.Wrap>
                    <S.Title>
                        {t("common:registration.uploadArticle")}
                    </S.Title>
                    <S.Description>
                        {t("common:registration.photoGuide")}
                    </S.Description>
                    <S.ImageWrapper>
                        <S.ScanGuideImage src={ScanGuide} alt="guide"/>
                    </S.ImageWrapper>
                    <S.DownloadLabel {...getRootProps()}>
                        {!isDragActive && (
                            <S.DownloadText>
                                {t("common:registration.uploadFile")} <br /> {t("common:registration.drag")}
                                <br />
                            </S.DownloadText>
                        )}
                        <input type="file" {...getInputProps()} onClick={open}/>
                    </S.DownloadLabel>
                    {fileRejections.length > 0 && <S.DownloadText color="red">{t("common:registration.wrongFileType")}</S.DownloadText>}
                    {barcodeNotFound && <S.DownloadText color="red">{t("common:registration.articleNotFound")}</S.DownloadText>}
                </S.Wrap>
            </S.ModalWrap>
        </S.ModalOverlay>
    )
}

export default BarcodeModal;
