import styled, { css } from "styled-components";

export const GuaranteeButtonsWrap = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: none;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 8px;
      margin-top: 24px;
    }
  `}
`;

export const GuaranteeButton = styled.button`
  ${({ theme: { colors } }) => css`
    display: flex;
    background-color: ${colors.backgroundGray};
    border-radius: 10.5px;
    font-size: 12px;
    color: ${colors.darkGray};
    padding: 8px;
    text-align: center;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    width: 100%;
  `}
`;

export const GuaranteeSubTitle = styled.p`
  font-size: 13px;
  line-height: 1.5;
`;

export const GuaranteeRulesWrap = styled.div`
  ${({ theme: { breakpoints, colors } }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-right: 30px;
    width: 100%;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: ${colors.bgGray};
      width: 1160px;
      margin-left: 64px;
      margin-right: 64px;
    }

    &::-webkit-scrollbar {
      height: 8px;
      background-color: white;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${colors.black};
    }


    h4 {
      display: flex;
      gap: 16px;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 400;

      @media (max-width: ${breakpoints.maxMobileWidth}) {
        font-size: 13px;
      }
    }
  `}
`;

export const TitleParagraph = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 1.5;
`;

export const IconArrowWrap = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) => css`
    svg {
      rotate: ${isOpen ? "" : "180deg"};
    }
  `}
`;

export const GuaranteeRulesHeader = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: block;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      display: none;
    }
  `}
`;

export const Period = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const RulesWrapper = styled.div`
  section {
    dl {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }
  }
  label {
    h4 {
      margin-bottom: 16px;
      margin-top: 16px;
    }
  }
`;

export const GuaranteeTimeItem = styled.li`
  list-style: disc;
  font-size: 12px;
  line-height: 1.5;
  margin-left: 20px;
`;

export const PeriodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 8px;
`;

export const GuaranteePageContent = styled.section`
  ${({ theme: { breakpoints, colors } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    row-gap: 8px;
    height: max-content;
    
    section {
      display: none;
    }
    
    label {
      cursor: pointer;
    }

    h2 {
      font-size: 40px;
      line-height: 1.2;
      font-weight: 700;
      margin-bottom: 4px;
    }

    h3 {
      font-size: 22px;
      line-height: 1.5;
      font-weight: 400;
      margin-bottom: 32px;
    }

    dl {
      font-size: 14px;
      line-height: 1.5;
      margin: 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      white-space: nowrap;
    }

    dt {
      font-size: 14px;
      line-height: 1.5;
      color: ${colors.darkGray};
      width: calc(100% - 20px);
      position: relative;
      
      span {
        background-color: #fff;
        padding-right: 5px;
        position: relative;
        z-index: 1;
      }
      
      &:after {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAABCAAAAAA+i0toAAAAAnRSTlMA/1uRIrUAAAAMSURBVHheY7j1/z8ABY8C2UtBe8oAAAAASUVORK5CYII=) 50px 0 repeat-x;
        content: "";
        height: 1px;
        left: 0;
        margin-top: 1.1em;
        position: absolute;
        right: 0;
      }
    }

    dd {
      font-size: 14px;
      line-height: 1.5;
    }

    p {
      font-size: 14px;
      line-height: 1.5;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 8px 0;
    }

    li {
      list-style: disc;
      font-size: 14px;
      line-height: 1.5;
      margin-left: 20px;
    }

    input:checked + section {
      display: block;
    }

    input[type="checkbox"] {
      display: none;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      dl {
        font-size: 13px;
        justify-content: space-between;
      }

      dt {
        font-size: 13px;
        min-width: auto;
        margin-left: 0;
      }

      dd {
        font-size: 13px;
      }

      p {
        font-size: 12px;
      }

      li {
        font-size: 12px;
      }
      
    }
  `};
`;
