import React, { FC } from "react";
import * as S from "./styled";
import { ProductDetailDTO, ProductWarrantyGridDTO } from "@app/api/generated-api";
import dayjs from "dayjs";
import { useTranslation } from "next-i18next";

type Props = {
  product: ProductDetailDTO | ProductWarrantyGridDTO;
  mobile?: boolean;
};

//typeguard
const isProductDetailDTO = (product: ProductDetailDTO | ProductWarrantyGridDTO): product is ProductDetailDTO => {
  return (product as ProductDetailDTO).product_set !== undefined;
};

const GuaranteeRules: FC<Props> = ({ product, mobile }) => {
  const { t } = useTranslation();

  return (
    <S.GuaranteePageContent>
      <S.GuaranteeRulesHeader>
        <h2>{t("common:productDetail.warrantyTerms")}</h2>
        <h3>{t("common:productDetail.warrantyDescription")}</h3>
      </S.GuaranteeRulesHeader>
      <S.GuaranteeRulesWrap>
        <S.GuaranteeButtonsWrap>
          {mobile &&
            isProductDetailDTO(product) &&
            product.product_set.warranties.length > 1 &&
            product.product_set.warranties.map((item) => (
              <S.GuaranteeButton key={item.id}>
                Гарантия на {product.product_set.contents.find((content) => content.guid === item.product_guid).name}
                до {dayjs(item.extended_warranty_expires_at ?? item.default_warranty_expires_at).format("DD.MM.YYYY")}
              </S.GuaranteeButton>
            ))}
        </S.GuaranteeButtonsWrap>
        <S.RulesWrapper dangerouslySetInnerHTML={{ __html: product.warranty_rule }} />
      </S.GuaranteeRulesWrap>
    </S.GuaranteePageContent>
  );
};

export default GuaranteeRules;
