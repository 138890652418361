import React, {FC} from 'react'
import * as S from "./styled";
import {useTranslation} from "next-i18next";

interface IWrongWarrantyDesc {
    wrong: "period" | "product"
}

const WrongWarrantyDesc: FC<IWrongWarrantyDesc> = ({wrong}) => {
    const { t } = useTranslation();

    return (
        <S.WarrantyText>
            {wrong === "period" ? t("common:myGoods.wrongWarrantyPeriod") : t("common:myGoods.wrongWarrantyProduct")}
            {t("common:myGoods.wrongWarrantyPart1")}
            <S.WarrantyMail href={'mailto:service@aquaart.ru'}>
                service@aquaart.ru
            </S.WarrantyMail>
            ,
            <S.WarrantyMail href={'mailto:servicemsk@aquaart.ru'}>
                {" servicemsk@aquaart.ru"}
            </S.WarrantyMail>
            {t("common:myGoods.wrongWarrantyPart2")}
        </S.WarrantyText>
    )
}

export default WrongWarrantyDesc;